.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 50%;
    height: 50%;
  }

  .centeredButton {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #555555;
    border: 2px solid #555555;
    width: 50%;
    height: 50%;
    color: white;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    transition-duration: 0.4s;
    cursor: pointer;
  }

  .centeredButton:hover {
    background-color: white;
    color: #555555;
  }

  .centeredButton:active {
    background-color: white;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .centeredError {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 50%;
    height: 50%;
    color: red;
  }

  .drowDown{
    // height: 27px;
    width: 163px;
    margin-bottom: 20px;
  }

  .radioSex{
    margin-left: 2px;
    margin-right: 5px;
    margin-bottom: 2px;
    margin-top: 8px;
  }

  .inputs{
    height: 27px;
    width: 163px;
    margin-bottom: 2px;
  }

  .centerBottomMargin {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 50%;
    height: 50%;
  }

  .contain {
    margin-bottom: 0px;
  }
  
  .imageProduct {
    width: 100%;
    height: 100%;
    min-width: 130px;
}