.imageProduct {
    width: 100%;
    height: 100%;
    min-width: 130px;
}

.rowProduct {
    margin-top: 10px;
    margin-bottom: 10px;
}

.colCentered {
    display: grid;
    place-items: center;
}
